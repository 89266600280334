import i18n from '@/i18n';

export default {
  methods: {
    formatDateToReadable(str) {
      const date = new Date(str);
      return `${(`0${date.getDate()}`).slice(-2)}. ${this.$t(`months.${date.getMonth() + 1}`)} ${date.getFullYear()}`;
    },

    calculateYears(to) {
      const today = new Date();
      const birth = new Date(to);
      let age = today.getFullYear() - birth.getFullYear();
      const months = today.getMonth() - birth.getMonth();
      if (months < 0 || (months === 0 && today.getDate() < birth.getDate())) {
        age -= 1;
      }

      return Math.floor(age);
    },
  },
  filters: {
    formatDate(str) {
      const date = new Date(str);
      return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
    },

    formatDateTime(str, clock = false) {
      const date = new Date(str);
      return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()} | ${date.toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit',
      })}${(clock) ? ` ${i18n.t('dateTime.time.clock')}` : ''}`;
    },

    formatTime(str) {
      const date = new Date(str);
      return `${date.toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    },
  },
};
