import {
  removeLead,
  updateStatus,
  updateLead,
} from '@/api/leads.api';
import { createProject } from '@/api/projects.api';

export default {
  computed: {},
  methods: {
    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t('leadActions.confirm')} ${this.$t(`leadActions.actions.${action}`)}`);
    },

    handleStatusChange(status) {
      this.$store.dispatch('isLoggedIn').then((token) => {
        updateStatus(this.lead.id, status, token)
          .then(() => {
            this.$eventBus.$emit('notificate', { title: this.$t('notifications.leadActions.updateStatus.title'), message: this.$t('notifications.leadActions.updateStatus.message.label') });
            this.$emit('update');
          })
          .catch((error) => {
            this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
          });
      });
    },

    handleDelete(triggerUpdate = true) {
      if (!this.confirmAction('delete')) {
        return false;
      }

      this.$store.dispatch('isLoggedIn').then((token) => {
        removeLead(this.lead.id, token)
          .then(() => {
            this.$eventBus.$emit('notificate', { title: this.$t('notifications.leadActions.deleted.title'), message: this.$t('notifications.leadActions.deleted.message.label') });

            if (triggerUpdate) {
              this.$emit('update');
            }
          })
          .catch((error) => {
            this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
          });
      });

      return true;
    },

    async showRelatedProject(projectId) {
      this.$router.push({ name: 'ProjectDetail', params: { id: projectId, init: true, lang: this.$i18n.locale } });
    },

    async createProjectFromLead(lead) {
      try {
        const token = await this.$store.dispatch('isLoggedIn');
        const project = await createProject({
          title: lead.title,
        }, token);

        await updateLead(lead.id, { project_id: project.data.data.id }, token);

        this.$eventBus.$emit('notificate', {
          title: this.$t('notifications.leadActions.createProjectFromLead.title'),
          message: this.$t('notifications.leadActions.createProjectFromLead.message.label'),
        });

        this.$router.push({ name: 'ProjectDetail', params: { id: project.data.data.id, init: true, lang: this.$i18n.locale } });
      } catch (error) {
        this.$eventBus.$emit('notificate', {
          status: error.response.status,
          message: error.response.message,
        });
      }
    },
  },
};
