import httpClient from './httpClient';

const END_POINT = '/projects';
const mapAuthHeader = (token, opts = {}) => ((token) ? {
  headers: {
    Authorization: `Bearer ${token}`,
    ...opts,
  },
} : {});

const listProjects = (filter, token) => httpClient.get(`${END_POINT}?${new URLSearchParams(filter).toString()}`, {
  ...mapAuthHeader(token),
});

const createProject = (project, token) => httpClient.post(END_POINT, project, {
  ...mapAuthHeader(token),
});

const getProject = (id, token) => httpClient.get(`${END_POINT}/${id}`, {
  ...mapAuthHeader(token),
});

const updateProject = (id, project, token) => httpClient.put(`${END_POINT}/${id}`, project, {
  ...mapAuthHeader(token),
});

const patchProject = (id, fields, token) => httpClient.patch(`${END_POINT}/${id}`, fields, {
  ...mapAuthHeader(token),
});

const approveProject = (id, token, data = {}) => httpClient.patch(`${END_POINT}/${id}/approve`, data, {
  ...mapAuthHeader(token),
});

const publishProject = (id, data, token) => httpClient.patch(`${END_POINT}/${id}/publish`, data, {
  ...mapAuthHeader(token),
});

const removeProject = (id, token) => httpClient.delete(`${END_POINT}/${id}`, {
  ...mapAuthHeader(token),
});

const applyProject = (id, data, token) => httpClient.post(`${END_POINT}/${id}/apply`, data, {
  ...mapAuthHeader(token),
});

const listProjectApplications = (projectid, filter, token) => httpClient.get(`${END_POINT}/${(projectid) ? `${projectid}/` : ''}applications?${new URLSearchParams(filter).toString()}`, {
  ...mapAuthHeader(token),
});

const getProjectApplication = (projectid, applicationid, token) => httpClient.get(`${END_POINT}/${projectid}/applications/${applicationid}`, {
  ...mapAuthHeader(token),
});

const patchProjectApplication = (projectid, applicationid, fields, token) => httpClient.patch(`${END_POINT}/${projectid}/applications/${applicationid}`, fields, {
  ...mapAuthHeader(token),
});

const sendProjectApplicationMessage = (projectid, applicationid, fields, token) => httpClient.post(`${END_POINT}/${projectid}/applications/${applicationid}/sendMessage`, fields, {
  ...mapAuthHeader(token),
});

const forwardProjectApplicationOriginalMessage = (projectid, applicationid, fields, token) => httpClient.post(`${END_POINT}/${projectid}/applications/${applicationid}/forwardOriginalMessage`, fields, {
  ...mapAuthHeader(token),
});

const sendCampaign = (projectid, recipients, token) => httpClient.post(`${END_POINT}/${projectid}/campaign`, { recipients }, {
  ...mapAuthHeader(token),
});

export {
  listProjects,
  createProject,
  getProject,
  updateProject,
  patchProject,
  approveProject,
  publishProject,
  removeProject,
  applyProject,
  listProjectApplications,
  getProjectApplication,
  patchProjectApplication,
  sendProjectApplicationMessage,
  forwardProjectApplicationOriginalMessage,
  sendCampaign,
};
