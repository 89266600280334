import httpClient from './httpClient';

const END_POINT = '/matches';
const mapAuthHeader = (token, opts = {}) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    ...opts,
  },
});

const removeMatch = (id, token) => httpClient.delete(`${END_POINT}/${id}`, {
  ...mapAuthHeader(token),
});

const updatePhase = (id, phase, token) => httpClient.patch(`${END_POINT}/${id}/phase`, { phase }, {
  ...mapAuthHeader(token),
});

const createMatch = (leadId, managerUuid, token) => httpClient.post(`${END_POINT}`, { lead_id: leadId, manager_uuid: managerUuid }, {
  ...mapAuthHeader(token),
});

export {
  removeMatch,
  updatePhase,
  createMatch,
};
