<template>
  <div class="rich-text" v-html="html"></div>
</template>

<script>
export default {
  name: 'RichText',
  props: {
    html: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/rich-text.scss"></style>
