<template>
  <div class="project-list">
    <div class="_filter">
      <div v-if="!$matchMedia.sm" class="row" :class="{' display-flex  align-items-center': !$matchMedia.md}">
        <div class="col-4  col-lg-5  col-md-8  valign-bottom">
          <InputText name="title"
                     base="projectsList.filter"
                     class="_search"
                     :class="{'-light': loggedIn}"
                     v-model="selectedFilter.title"
                     @input="updateList"
                     type="text">
            <Icon class="-size-20"><SearchSVG></SearchSVG></Icon>
          </InputText>
        </div>
        <div class="col-4  col-lg-5  col-md-8  valign-bottom">
          <InputSelect name="operationals"
                       :class="{'-light': loggedIn}"
                       base="projectsList.filter"
                       :value="(selectedFilter.operationals.length > 0) ? 'add' : 'all'"
                       @input="handleSelectedFilter('operationals', $event)">
            <option selected value="all">
              {{ $t('projectsList.filter.inputs.operationals.placeholder') }}
            </option>
            <option disabled value="add">
              {{ $t('projectsList.filter.add') }}
            </option>
            <template v-slot:options>
              <optgroup :label="$t('projectDetails.operationals.inputs.main.label')">
                <option v-for="(value, key) in $t('categories.operationals.main')"
                        :key="key"
                        :disabled="selectedFilter.operationals.includes(key)"
                        :value="key">{{ value }}</option>
              </optgroup>
              <optgroup v-for="(group, groupkey) in subOperationals"
                        :key="groupkey"
                        :label="$t(`categories.operationals.main.${groupkey}`)">
                <option v-for="(value, key) in group"
                        :key="key"
                        :disabled="selectedFilter.operationals.includes(key)"
                        :value="`${groupkey}_${key}`">{{ value }}</option>
              </optgroup>
            </template>
          </InputSelect>
        </div>
        <div class="col-4  col-lg-5  col-md-8  valign-bottom">
          <InputSelect name="industry"
                       :class="{'-light': loggedIn}"
                       base="projectsList.filter"
                       :value="(selectedFilter.industry.length > 0) ? 'add' : 'all'"
                       @input="handleSelectedFilter('industry', $event)">
            <option selected value="all">
              {{ $t('projectsList.filter.inputs.industry.placeholder') }}
            </option>
            <option disabled value="add">
              {{ $t('projectsList.filter.add') }}
            </option>
            <template v-slot:options>
              <optgroup :label="$t('projectDetails.industry.inputs.main.label')">
                <option v-for="(value, key) in $t('categories.industries.main')"
                        :key="key"
                        :disabled="selectedFilter.industry.includes(key)"
                        :value="key">{{ value }}</option>
              </optgroup>
              <optgroup v-for="(group, groupkey) in subIndustries"
                        :key="groupkey"
                        :label="$t(`categories.industries.main.${groupkey}`)">
                <option v-for="(value, key) in group"
                        :key="key"
                        :disabled="selectedFilter.industry.includes(key)"
                        :value="`${groupkey}_${key}`">{{ value }}</option>
              </optgroup>
            </template>
          </InputSelect>
        </div>
        <div class="col-4  col-lg-5  col-md-8  valign-bottom">
          <InputSelect name="experience"
                       :class="{'-light': loggedIn}"
                       base="projectsList.filter"
                       :value="(selectedFilter.experience.length > 0) ? 'add' : 'all'"
                       @input="handleSelectedFilter('experience', $event)">
            <option selected value="all">
              {{ $t('projectsList.filter.inputs.experience.placeholder') }}
            </option>
            <option disabled value="add">
              {{ $t('projectsList.filter.add') }}
            </option>
            <template v-slot:options>
              <option v-for="(value, key) in $t('categories.experiences')"
                      :key="key"
                      :disabled="selectedFilter.experience.includes(key)"
                      :value="key">{{ value }}</option>
            </template>
          </InputSelect>
        </div>
        <div class="col-4  col-lg-5  col-md-8  valign-bottom" v-if="role === 'admin'">
          <InputSelect name="status"
                       class="-light"
                       base="projectsList.filter"
                       v-model="selectedFilter.status"
                       :options="$t('projectsList.filter.inputs.status.values')"
                       @input="updateList">
            <option selected value="">{{ $t('projectsList.filter.inputs.status.placeholder') }}</option>
          </InputSelect>
        </div>
        <div class="col-4  col-lg-5  col-md-8  valign-bottom" v-if="role === 'admin'">
          <InputSelect name="pc"
                       class="-light"
                       base="projectsList.filter"
                       v-model="selectedFilter.pc"
                       :options="$t('projectInternals.inputs.pc.values')"
                       @input="updateList">
            <option selected value="">{{ $t('projectsList.filter.inputs.pc.placeholder') }}</option>
          </InputSelect>
        </div>
        <div class="col-12  col-lg-9  col-md-24" v-if="role !== 'admin'">
          <InputCheckbox v-model="selectedFilter.application_deadline"
                         name="application_deadline"
                         base="projectsList.filter"
                         :class="['mt-s', {'-light': loggedIn}]"
                         @input="updateList">
          </InputCheckbox>
        </div>
      </div>
      <div v-if="$matchMedia.sm">
        <div class="row">
          <div class="col-md-24  mb-xs">
            <InputCheckbox v-model="selectedFilter.application_deadline"
                           :class="{'-light': loggedIn}"
                           name="application_deadline"
                           base="projectsList.filter"
                           @input="updateList">
            </InputCheckbox>
          </div>
        </div>
        <ContentBox>
          <div class="row">
            <div class="col-md-24  mb-xs-xxs">
              <InputSelect name="operationals"
                           base="projectsList.filter"
                           :value="(selectedFilter.operationals.length > 0) ? 'add' : 'all'"
                           @input="handleSelectedFilter('operationals', $event)">
                <option selected value="all">
                  {{ $t('projectsList.filter.inputs.operationals.placeholder') }}
                </option>
                <option disabled value="add">
                  {{ $t('projectsList.filter.add') }}
                </option>
                <template v-slot:options>
                  <optgroup :label="$t('projectDetails.operationals.inputs.main.label')">
                    <option v-for="(value, key) in $t('categories.operationals.main')"
                            :key="key"
                            :disabled="selectedFilter.operationals.includes(key)"
                            :value="key">{{ value }}</option>
                  </optgroup>
                  <optgroup v-for="(group, groupkey) in subOperationals"
                            :key="groupkey"
                            :label="$t(`categories.operationals.sub.${groupkey}`)">
                    <option v-for="(value, key) in group"
                            :key="key"
                            :disabled="selectedFilter.operationals.includes(key)"
                            :value="`${groupkey}_${key}`">{{ value }}</option>
                  </optgroup>
                </template>
              </InputSelect>
            </div>
            <div class="col-md-24  mb-xs-xxs  valign-bottom">
              <InputSelect name="industry"
                           base="projectsList.filter"
                           :value="(selectedFilter.industry.length > 0) ? 'add' : 'all'"
                           @input="handleSelectedFilter('industry', $event)">
                <option selected value="all">
                  {{ $t('projectsList.filter.inputs.industry.placeholder') }}
                </option>
                <option disabled value="add">
                  {{ $t('projectsList.filter.add') }}
                </option>
                <template v-slot:options>
                  <optgroup :label="$t('projectDetails.industry.inputs.main.label')">
                    <option v-for="(value, key) in $t('categories.industries.main')"
                            :key="key"
                            :disabled="selectedFilter.industry.includes(key)"
                            :value="key">{{ value }}</option>
                  </optgroup>
                  <optgroup v-for="(group, groupkey) in subIndustries"
                            :key="groupkey"
                            :label="$t(`categories.industries.sub.${groupkey}`)">
                    <option v-for="(value, key) in group"
                            :key="key"
                            :disabled="selectedFilter.industry.includes(key)"
                            :value="`${groupkey}_${key}`">{{ value }}</option>
                  </optgroup>
                </template>
              </InputSelect>
            </div>
            <div class="col-md-24  mb-xs-xxs  valign-bottom">
              <InputSelect name="experience"
                           base="projectsList.filter"
                           :value="(selectedFilter.experience.length > 0) ? 'add' : 'all'"
                           @input="handleSelectedFilter('experience', $event)">
                <option selected value="all">
                  {{ $t('projectsList.filter.inputs.experience.placeholder') }}
                </option>
                <option disabled value="add">
                  {{ $t('projectsList.filter.add') }}
                </option>
                <template v-slot:options>
                  <option v-for="(value, key) in $t('categories.experiences')"
                          :key="key"
                          :disabled="selectedFilter.experience.includes(key)"
                          :value="key">{{ value }}</option>
                </template>
              </InputSelect>
            </div>
          </div>
        </ContentBox>
      </div>
      <div class="row  pos-relative">
        <div class="col-24" v-if="filteredTagLabel">
          <div :class="['-label', loggedIn ? 'color-white' : 'color-blue-600']">
            {{ $t(`projectsList.filter.selection_label`) }}
          </div>
          <InputTag class="-light"
                    v-for="val in selectedFilter.operationals"
                    :value="val"
                    :key="val"
                    @remove="handleRemoveSelected('operationals', $event)">
            {{
              (!val.includes('_')) ?
              $t(`categories.operationals.main.${val}`) :
              $t(`categories.operationals.sub.${val.replace('_', '.')}`)
            }}
          </InputTag>
          <InputTag class="-light"
                    v-for="val in selectedFilter.industry"
                    :value="val"
                    :key="val"
                    @remove="handleRemoveSelected('industry', $event)">
            {{
              (!val.includes('_')) ?
              $t(`categories.industries.main.${val}`) :
              $t(`categories.industries.sub.${val.replace('_', '.')}`)
            }}
          </InputTag>
          <InputTag class="-light"
                    v-for="val in selectedFilter.experience"
                    :value="val"
                    :key="val"
                    @remove="handleRemoveSelected('experience', $event)">
            {{ $t(`categories.experiences.${val}`) }}
          </InputTag>
        </div>
      </div>
    </div>
    <div :class="['-label  text-uppercase', { 'color-white': loggedIn, 'color-blue-600': $matchMedia.xs }]">
      {{ $tc('projectsList.count', projects.length)}}
    </div>
    <ul>
      <li v-for="project in projects" :key="project.id" class="_project">
        <router-link :to="{
            name: (loggedIn) ? 'ProjectDetail' : 'PublicProjectDetail', params: {
            id: project.id,
            lang: $i18n.locale,
          },
        }">
          <ContentBox>
            <div class="_project-header">
              <div class="row">
                <div class="col-24" v-if="!$matchMedia.mdAndUp">
                  <template v-if="['published', 'closed', 'staffed', 'draft'].includes(project.status)">
                    <StatusTags v-if="!$matchMedia.sm" :label="true" :project="project"></StatusTags>
                    <template v-if="$matchMedia.sm">
                      <div class="display-flex  mb-xs">
                        <div class="mr-xxs  mb-xs  mb-xs-0">
                          <StatusTags :label="true" :project="project"></StatusTags>
                        </div>
                        <div>
                          <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium  line-height-1">{{ $t('projectsList.project.application_deadline') }}</div>
                          <div class="color-brand-blue  font-weight-medium">{{ project.application_deadline | formatDate }}</div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-if="['published', 'closed', 'staffed'].includes(project.status)">
                    <template v-if="!$matchMedia.sm">
                      <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium">{{ $t('projectsList.project.published_date') }}</div>
                      <div class="mb-xxs  color-brand-blue  font-weight-medium">{{ project.published_at | formatDate }}</div>
                      <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium">{{ $t('projectsList.project.application_deadline') }}</div>
                      <div class="color-brand-blue  font-weight-medium">{{ project.application_deadline | formatDate }}</div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="!$matchMedia.sm">
                      <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium">{{ $t('projectsList.project.published_date') }}</div>
                      <div class="mb-xxs  color-brand-blue  font-weight-medium">{{ project.published_at | formatDate }}</div>
                      <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium">{{ $t('projectsList.project.application_deadline') }}</div>
                      <div class="color-brand-blue  font-weight-medium">{{ project.application_deadline | formatDate }}</div>
                    </template>
                  </template>
                </div>
                <div class="col-19  col-lg-18  col-md-16  col-sm-24">
                  <h5 class="h5  font-weight-medium  mb-xs  _project-headline">{{ project.title }}</h5>
                  <p class="mb-xs  font-weight-normal  color-blue-1100" v-html="excerpt(project.intro, 250)" v-if="project.intro && !$matchMedia.xs"></p>
                  <p class="mb-xs  font-weight-normal  color-blue-1100" v-html="excerpt(project.intro, 60)" v-if="project.intro && $matchMedia.xs"></p>
                  <router-link class="link  -orange" :to="{
                        name: (loggedIn) ? 'ProjectDetail' : 'PublicProjectDetail', params: {
                        id: project.id,
                        lang: $i18n.locale,
                      },
                    }">
                    {{ $t('projectsList.project.link') }}<Icon class="-is-right -inline"><ArrowSVG></ArrowSVG></Icon>
                  </router-link>
                </div>
                <div class="col-4  col-offset-1  col-lg-6  col-lg-offset-0  col-md-6  col-md-offset-1  border-left  -blue-100" v-if="$matchMedia.mdAndUp">
                  <div class="row  display-flex  justify-content-center  align-items-center">
                    <div class="col-18  col-offset-6">
                      <StatusTags class="mb-xs  mb-sm-0" :label="true" :project="project"></StatusTags>
                      <template v-if="['published', 'closed', 'staffed'].includes(project.status)">
                        <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium">{{ $t('projectsList.project.published_date') }}</div>
                        <div class="mb-xxs  color-brand-blue  font-weight-medium">{{ project.published_at | formatDate }}</div>
                        <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium">{{ $t('projectsList.project.application_deadline') }}</div>
                        <div class="color-brand-blue  font-weight-medium">{{ project.application_deadline | formatDate }}</div>
                      </template>
                      <template v-else>
                        <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium">{{ $t('projectsList.project.createdAt') }}</div>
                        <div class="mb-xxs  color-brand-blue  font-weight-medium">{{ project.createdAt | formatDate }}</div>
                        <div class="color-blue-300  text-uppercase  font-size-xs  font-weight-medium">{{ $t('projectsList.project.lastEdited') }}</div>
                        <div class="color-brand-blue  font-weight-medium">{{ project.updatedAt | formatDate }}</div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContentBox>
        </router-link>
      </li>
    </ul>
    <div v-if="projects.length <= 0">
      <div class="row">
        <div class="col-6">
          <h5 class="h5  mt-xs  color-white  pos-relative">{{ $t('projectsList.noResults') }}</h5>
        </div>
      </div>
    </div>
    <div v-if="projects.length === (offset+1) * limit" class="text-center">
      <Button class="-orange" @click.native="loadMore">{{ $t('projectsList.loadMore') }}</Button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { listProjects } from '@/api/projects.api';
import {
  arrayUtils,
  objectUtils,
  parseDates,
  queryObjects,
} from '@/mixins';

import Icon from '@/components/atoms/Icon.vue';
import Button from '@/components/atoms/Button.vue';
import StatusTags from '@/components/modules/Project/StatusTags.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputTag from '@/components/elements/inputs/InputTag.vue';
import InputText from '@/components/elements/inputs/InputText.vue';

import ArrowSVG from '@/assets/icons/arrow.svg';
import SearchSVG from '@/assets/icons/search.svg';

export default {
  name: 'ProjectList',
  mixins: [parseDates, objectUtils, arrayUtils, queryObjects],
  props: {
    defaultFilter: {
      type: Object,
      default: () => ({
        operationals: [],
        industry: [],
        experience: [],
      }),
    },
    defaultSort: {
      type: Object,
      default: () => ({
        value: 'createdAt',
        direction: 'desc',
      }),
    },
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'isAdmin',
    ]),

    ...mapState([
      'role',
    ]),

    filteredTagLabel() {
      return this.selectedFilter.operationals.length > 0
      || this.selectedFilter.industry.length > 0
      || this.selectedFilter.experience.length > 0;
    },

    filter() {
      const {
        operationals,
        industry,
        application_deadline: deadline,
        title,
        ...filter
      } = this.selectedFilter;

      return {
        main_operationals: operationals.filter((key) => !key.includes('_')),
        sub_operationals: operationals.filter((key) => key.includes('_')),
        main_industry: industry.filter((key) => !key.includes('_')),
        sub_industry: industry.filter((key) => key.includes('_')),
        ...((deadline) && {
          status: 'openForApplication',
        }),
        ...((title) && {
          title: `like:%${title}%`,
        }),
        ...filter,
      };
    },

    sort() {
      return { ...this.defaultSort, ...this.selectedSort };
    },

    subOperationals() {
      return this.filterObj(this.$t('categories.operationals.sub'), (value) => Object.keys(value).length > 0);
    },

    subIndustries() {
      return this.filterObj(this.$t('categories.industries.sub'), (value) => Object.keys(value).length > 0);
    },

    queryFilter() {
      return {
        ...this.buildFilterQuery(this.filter, this.filterOptions),
        ...this.buildSortQuery(this.sort),
        limit: this.limit,
        offset: this.offset,
      };
    },
  },
  data() {
    return {
      projects: {},
      selectedFilter: {
        ...JSON.parse(JSON.stringify(this.defaultFilter)),
        ...{
          operationals: [],
          industry: [],
          experience: [],
        },
      },
      selectedSort: JSON.parse(JSON.stringify(this.defaultSort)),
      limit: 10,
      offset: 0,
    };
  },
  created() {
    this.filterOptions = {
      main_operationals: {
        logicalOp: 'or',
        compareOp: 'like',
      },
      sub_operationals: {
        logicalOp: 'or',
        compareOp: 'like',
      },
      main_industry: {
        logicalOp: 'or',
        compareOp: 'like',
      },
      sub_industry: {
        logicalOp: 'or',
        compareOp: 'like',
      },
      experience: {
        logicalOp: 'or',
        compareOp: 'eq',
      },
      application_deadline: {
        compareOp: 'gt',
      },
    };
  },
  activated() {
    if (!this.$parent.$parent.savedPosition) {
      this.updateList();
    }
  },
  // @TODO: mounted will double the update list call, prevent or fix update on activated hook
  mounted() {
    this.updateList();
  },
  methods: {
    excerpt(str, maxLength) {
      const index = str.indexOf(' ', maxLength);
      return (index === -1) ? str : `${str.substr(0, index)} ...`;
    },

    recent(project) {
      return (new Date(project.published_at).getTime() > (new Date().getTime() - (7 * 24 * 60 * 60 * 1000)));
    },

    closed(project) {
      return (new Date(project.application_deadline).getTime() < (new Date().getTime())) || project.status === 'closed';
    },

    handleSelectedFilter(field, value) {
      if (value === 'all') {
        this.$set(this.selectedFilter, field, []);
      } else {
        this.selectedFilter[field].push(value);
      }

      this.updateList();
    },

    handleRemoveSelected(field, value) {
      this.$set(this.selectedFilter, field, this.removeFromArray(this.selectedFilter[field], value));
      this.updateList();
    },

    updateList() {
      this.offset = 0;
      this.fetchProjects();
    },

    loadMore() {
      this.offset += 1;
      this.fetchProjects();
    },

    fetchProjects() {
      if (this.loggedIn) {
        this.$store.dispatch('isLoggedIn')
          .then((token) => {
            listProjects(this.queryFilter, token)
              .then((response) => response.data)
              .then(this.handleProjectsData);
          });
      } else {
        listProjects(this.queryFilter)
          .then((response) => response.data)
          .then(this.handleProjectsData);
      }
    },

    handleProjectsData({ data }) {
      if (this.offset === 0) {
        this.$set(this, 'projects', data);
      } else {
        this.$set(this, 'projects', [...this.projects, ...data]);
      }
    },
  },
  components: {
    Icon,
    Button,
    ContentBox,
    InputCheckbox,
    InputSelect,
    InputTag,
    InputText,
    ArrowSVG,
    SearchSVG,
    StatusTags,
  },
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/link.scss"></style>
<style scoped lang="scss" src="@/sass/08_modules/project-list.scss"></style>
