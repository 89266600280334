<template>
  <div :class="{'pos-relative': true, 'content-overlay-box': !loggedIn}">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContentOverlayBox',
  computed: {
    ...mapGetters([
      'loggedIn',
    ]),
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/content-overlay-box.scss"></style>
