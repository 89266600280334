import { downloadPdfVita } from '@/api/admins.api';
import { removeMatch } from '@/api/matches.api';

export default {
  computed: {},
  methods: {
    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t('matchListActions.confirm')} ${this.$t(`matchListActions.actions.${action}`)}`);
    },

    handleDelete(id = this.id) {
      if (!this.confirmAction('delete')) {
        return;
      }

      this.$store.dispatch('isLoggedIn').then((token) => {
        removeMatch(id, token)
          .then(() => {
            this.$eventBus.$emit('notificate', { title: this.$t('notifications.matchActions.deleted.title'), message: this.$t('notifications.matchActions.deleted.message.label') });
            this.$emit('update');
          })
          .catch((error) => {
            this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
          });
      });
    },

    downloadPdf(language = 'de') {
      const cvWindow = window.open();

      this.$store
        .dispatch('isLoggedIn')
        .then((token) => downloadPdfVita(this.managerUuid, {}, token, language))
        .then((res) => {
          const file = new Blob([res.data], { type: 'application/pdf' });
          cvWindow.location.href = URL.createObjectURL(file);
        })
        .catch(() => {
          cvWindow.location.href = '/404';
          this.$eventBus.$emit('notificate', { message: this.$t('notifications.userPdfExport.message.notDownloadable'), status: 500 });
        });
    },
  },
};
