<template>
  <div class="project-detail">
    <router-link class="font-weight-medium  pl-xs-xs" :to="{name: 'PublicProjects', params: { lang: $i18n.locale } }">
      <Icon class="pr-xxs  -rotate-180">
        <ArrowSVG></ArrowSVG>
      </Icon>
      {{ $t('projectDetails.buttons.back') }}
    </router-link>
      <div class="row">
        <div class="col-24">
          <ProjectHeader :id="project.id" :project="project"></ProjectHeader>
        </div>
      </div>
      <div class="row">
          <div class="col-19  col-lg-16  col-md-24">
            <ContentOverlayBox>
              <ProjectContent ref="content" v-if="project.id" :project="project"></ProjectContent>
            </ContentOverlayBox>
          </div>
        <div class="col-5  col-lg-8  col-md-24">
          <ProjectPrompt :id="id"></ProjectPrompt>
        </div>
      </div>
  </div>
</template>

<script>
import { getProject } from '@/api/projects.api';

import Icon from '@/components/atoms/Icon.vue';
import ContentOverlayBox from '@/components/elements/ContentOverlayBox.vue';
import ProjectHeader from '@/components/modules/Project/public/Header.vue';
import ProjectContent from '@/components/modules/Project/public/Content.vue';
import ProjectPrompt from '@/components/modules/Project/public/Prompt.vue';

import ArrowSVG from '@/assets/icons/arrow.svg';

export default {
  name: 'PublicProjectDetail',
  props: {
    id: Number,
  },
  data() {
    return {
      project: {},
    };
  },
  mounted() {
    this.fetchProject();
  },
  methods: {
    fetchProject() {
      getProject(parseInt(this.id, 10))
        .then((response) => response.data)
        .then(({ data }) => {
          this.$set(this, 'project', data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.replace({ name: 'NotFound', params: { lang: this.$i18n.locale } });
          }
        });
    },
  },
  components: {
    Icon,
    ContentOverlayBox,
    ProjectHeader,
    ProjectContent,
    ProjectPrompt,
    ArrowSVG,
  },
  metaInfo() {
    return {
      title: this.project.title,
      meta: [
        {
          property: 'description',
          content: this.project.intro,
          vmid: 'description',
        },
        {
          property: 'og:title',
          content: this.project.title,
          vmid: 'og:title',
        },
        {
          property: 'og:description',
          content: this.project.intro,
          vmid: 'og:description',
        },
      ],
    };
  },
};
</script>
