<template>
  <div class="projects-list">
    <div class="row">
      <div class="col-24">
        <div class="_header  mb-m  mt-m-less  mb-xs-0  pos-relative">
          <div class="row">
            <div class="col-24">
              <h2 class="h2  mb-0  mt-0  font-weight-light" :class="{'font-size-xl  font-weight-medium': $matchMedia.xs}">{{ $t('projectsList.headline') }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-19  col-lg-24">
        <ProjectList :defaultFilter="filter" :defaultSort="sort"></ProjectList>
      </div>
      <div class="col-5  col-lg-24">
        <ProjectPrompt></ProjectPrompt>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectList from '@/components/modules/Project/List.vue';
import ProjectPrompt from '@/components/modules/Project/public/Prompt.vue';

export default {
  name: 'PublicProjects',
  data() {
    return {
      filter: {
        application_deadline: true,
      },
      sort: {
        value: 'published_at',
        direction: 'desc',
      },
    };
  },
  components: {
    ProjectList,
    ProjectPrompt,
  },
  metaInfo() {
    return {
      title: this.$t('metaData.projectList.title'),
    };
  },
};
</script>
