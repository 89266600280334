<template>
  <div class="input-tag">
    <slot></slot>
    <a v-if="!disabled" class="_remove" @click.stop="$emit('remove', value)">&#10005;</a>
  </div>
</template>

<script>
export default {
  name: 'InputTag',
  props: {
    value: [String, Object],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-tag.scss"></style>
