import { getUserAsset } from '@/api/users.api';
import { downloadPdfVita } from '@/api/admins.api';

export default {
  methods: {
    getAvatar(uuid) {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          getUserAsset(uuid, 'avatar', token)
            .then((res) => {
              const file = new Blob([res.data], { type: 'image/jpg' });
              this.blobToBase64(file)
                .then((imgBase64) => {
                  this.$set(this, 'avatar', imgBase64);
                });
            })
            .catch(() => {});
        });
    },

    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },

    downloadPdf(language = 'de') {
      if (this.role !== 'admin') return;

      const cvWindow = window.open();

      this.$store.dispatch('isLoggedIn')
        .then((token) => downloadPdfVita(this.uuid, {}, token, language))
        .then((res) => {
          const file = new Blob([res.data], { type: 'application/pdf' });
          cvWindow.location.href = URL.createObjectURL((file));
        })
        .catch(() => {
          cvWindow.location.href = '/404';
          this.$eventBus.$emit('notificate', { message: this.$t('notifications.userPdfExport.message.notDownloadable'), status: 500 });
        });
    },
  },
};
