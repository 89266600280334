import { camelCase, snakeCase, upperFirst } from 'lodash-es';

export default {
  methods: {
    toSnakeCase(str) {
      return snakeCase(str);
    },
    toCamelCase(str) {
      return camelCase(str);
    },
    toPascalCase(str) {
      return upperFirst(camelCase(str));
    },
  },
  filters: {
    replaceLinebreaks(str) {
      return str.replace(/\n/g, '<br/>');
    },
  },
};
