<template>
  <section class="project-content">
    <ContentBox>
      <div class="row  mb-m">
        <div class="col-24">
          <h4 class="font-weight-normal" :class="{'font-size-xl': $matchMedia.xs}">
            {{ $t('projectContent.headline') }}
          </h4>
          <div v-html="projectContent.intro" class="font-weight-normal  font-size-l"></div>
        </div>
      </div>
      <ContentBox class="mb-m">
        <div class="row">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectContent.inputs.description.headline') }}</h6>
            <RichText v-if="projectContent.description" :html="projectContent.description" />
          </div>
        </div>
      </ContentBox>
      <ContentBox class="mb-m">
        <div class="row">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectContent.inputs.requirements.headline') }}</h6>
            <RichText v-if="projectContent.requirements" :html="projectContent.requirements" />
          </div>
        </div>
      </ContentBox>
      <ContentBox class="mb-m">
        <div class="row">
          <div class="col-24">
            <h6 class="h6">{{ $t('projectContent.inputs.additional_infos.headline') }}</h6>
            <RichText v-if="projectContent.additional_infos" :html="projectContent.additional_infos" />
          </div>
        </div>
      </ContentBox>
      <slot name="applyCta"></slot>
    </ContentBox>
  </section>
</template>

<script>
import formatStrings from '@/mixins/formatStrings';

import ContentBox from '@/components/elements/ContentBox.vue';
import RichText from '@/components/elements/RichText.vue';

export default {
  name: 'ProjectContent',
  mixins: [formatStrings],
  props: {
    project: Object,
    init: Boolean,
  },
  data() {
    return {
      projectContent: {},
    };
  },
  watch: {
    project() {
      this.initContent();
    },
  },
  mounted() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.projectContent = {
        intro: this.project.intro,
        description: this.project.description,
        requirements: this.project.requirements,
        additional_infos: this.project.additional_infos,
      };
    },
  },
  components: {
    ContentBox,
    RichText,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-content.scss"></style>
<style scoped lang="scss" src="@/sass/07_elements/content-box.scss"></style>
