<template>
  <div class="tag">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tag',
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/tag.scss"></style>
