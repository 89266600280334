<template>
  <div class="content-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ContentBox',
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/content-box.scss"></style>
