<template>
  <div class="project-prompt">
    <ContentBox class="-blue-50">
      <h4 class="h4">{{ $t('projectPrompt.headline') }}</h4>
      <p>{{ $t('projectPrompt.text[0]') }}</p>
      <p class="mb-m">{{ $t('projectPrompt.text[1]') }}</p>
      <router-link :to="toLogin" v-slot="{ href, navigate }">
        <Button :href="href" @click.native="navigate" class="-orange  -outlined  mr-xs  mb-xs-xs">
          {{ $t('projectPrompt.button.login.label') }}
        </Button>
      </router-link>
      <router-link :to="toSignup" v-slot="{ href, navigate }">
        <Button :href="href" @click.native="navigate" class="-orange">
          {{ $t('projectPrompt.button.signup.label') }}</Button>
      </router-link>
    </ContentBox>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button.vue';
import ContentBox from '@/components/elements/ContentBox.vue';

export default {
  name: 'ProjectPrompt',
  props: {
    id: Number,
  },
  data() {
    return {
      toLogin: {
        name: 'Login',
        params: {
          lang: this.$i18n.locale,
        },
        query: {
          redirect: `/projects/${this.id}`,
        },
      },
      toSignup: {
        name: 'Signup',
        params: {
          lang: this.$i18n.locale,
        },
      },
    };
  },
  components: {
    Button,
    ContentBox,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-prompt.scss"></style>
