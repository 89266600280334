<template>
  <div :class="['input-wrapper', { '-small': alt === 'small' }, { '-head': alt === 'big' }]">
    <label :for="name">
      <slot name="label">
        <template v-if="$te(`${base}.inputs.${name}.label`) && alt !== 'small'">
          {{ $t(`${base}.inputs.${name}.label`) }}
        </template>
      </slot>
    </label>
    <input :type="type"
           :name="name"
           :placeholder="placeholderOverride || (($te(`${base}.inputs.${name}.placeholder`)) ? $t(`${base}.inputs.${name}.placeholder`) : false)"
           :value="value"
           :disabled="disabled"
           :min="min"
           :max="max"
           :readonly="readonly"
           :autocomplete="autocomplete"
           @change="updateInput($event)"
           @input="onInput($event)">
    <slot></slot>
    <div class="error-msg" v-if="$te(`${base}.inputs.${name}.error`)">
      <slot name="errors">
        {{ $t(`${base}.inputs.${name}.error`) }}
      </slot>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';

export default {
  name: 'InputText',
  props: {
    base: String,
    type: String,
    name: String,
    placeholderOverride: String,
    value: [String, Number],
    emptyValue: [String, Number],
    min: [String, Number],
    max: [String, Number],
    alt: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput: debounce(function cb(e) {
      this.updateInput(e);
    }, 500),

    updateInput(e) {
      let { value } = e.target;

      if (value === '' && this.emptyValue !== undefined) {
        value = this.emptyValue;
      }

      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-text.scss"></style>
