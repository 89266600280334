<template>
  <div class="checkbox-wrapper">
    <input type="checkbox"
           :name="name"
           :id="`${idPrefix}${base}-${name}`"
           v-on:input="updateValue($event.target.checked)"
           :disabled="disabled"
           :checked="checked">
    <label :for="`${idPrefix}${base}-${name}`">
      <slot name="label">
        <span v-if="$te(`${base}.inputs.${name}.label`)" v-html="$t(`${base}.inputs.${name}.label`)"></span>
      </slot>
    </label>
    <div class="error-msg" v-if="$te(`${base}.inputs.${name}.error`)">
      <slot name="errors">
        {{ $t(`${base}.inputs.${name}.error`) }}
      </slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'InputCheckbox',
  props: {
    base: String,
    name: String,
    id: String,
    value: {
      type: [Boolean, Array, String],
    },
    inputValue: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked() {
      return (Array.isArray(this.value)) ? this.value.indexOf(this.inputValue) >= 0 : this.value;
    },

    idPrefix() {
      return this.id ? `${this.id}-` : _.uniqueId();
    },
  },
  methods: {
    updateValue(value) {
      if (Array.isArray(this.value)) {
        let currentValue = [...this.value];

        if (value) {
          currentValue.push(this.inputValue);
        } else {
          currentValue = currentValue.filter((item) => item !== this.inputValue);
        }

        this.$emit('input', currentValue);
      } else if (this.inputValue && value) {
        this.$emit('input', this.inputValue);
      } else {
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/input-checkbox.scss"></style>
