import httpClient from './httpClient';

const END_POINT = '/leads';
const mapAuthHeader = (token, opts = {}) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    ...opts,
  },
});

const listLeads = (filter, token) => httpClient.get(`${END_POINT}?${new URLSearchParams(filter).toString()}`, {
  ...mapAuthHeader(token),
});

const getLead = (id, token) => httpClient.get(`${END_POINT}/${id}`, {
  ...mapAuthHeader(token),
});

const removeLead = (id, token) => httpClient.delete(`${END_POINT}/${id}`, {
  ...mapAuthHeader(token),
});

const createLead = (lead, token) => httpClient.post(`${END_POINT}/`, lead, {
  ...mapAuthHeader(token),
});

const updateLead = (id, lead, token) => httpClient.patch(`${END_POINT}/${id}`, lead, {
  ...mapAuthHeader(token),
});

const updateStatus = (id, status, token) => httpClient.patch(`${END_POINT}/${id}/status`, { status }, {
  ...mapAuthHeader(token),
});

export {
  listLeads,
  getLead,
  removeLead,
  updateStatus,
  createLead,
  updateLead,
};
