<template>
  <div class="status-tags">
    <template v-if="!$matchMedia.sm">
      <div v-if="label" class="_subheadline">{{ $t('projectsList.project.status') }}</div>
    </template>
    <Tag v-if="recent(project) && project.status !== 'staffed'" class="-slim  -green">{{ $t('projectsList.project.new') }}</Tag>
    <Tag v-if="project.status === 'draft'" class="-slim  -grey">{{ $t('states.projects.draft.title') }}</Tag>
    <Tag v-else-if="project.status === 'approval'" class="-slim  -grey">{{ $t('states.projects.approval.title') }}</Tag>
    <Tag v-else-if="project.status === 'staffed'" class="-slim  -grey">
      {{ $t(`states.projects.${this.$route.path.includes('public') ? 'closed' : 'staffed'}.title`) }}
    </Tag>
    <Tag v-else-if="closed(project)" class="-slim  -grey">{{ $t('states.projects.closed.title') }}</Tag>
    <Tag v-else-if="project.status === 'published'" class="-slim  -blue">{{ $t('states.projects.open.title') }}</Tag>
  </div>
</template>

<script>
import Tag from '@/components/atoms/Tag.vue';

export default {
  name: 'StatusTags',
  props: {
    label: Boolean,
    project: Object,
  },
  methods: {
    recent(project) {
      return (new Date(project.published_at).getTime() > (new Date().getTime() - (7 * 24 * 60 * 60 * 1000)));
    },

    closed(project) {
      return (new Date(project.application_deadline).getTime() < (new Date().getTime())) || project.status === 'closed';
    },
  },
  components: {
    Tag,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/status-tags.scss"></style>
