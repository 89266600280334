export default {
  methods: {
    chunkArray(arr, size) {
      const results = [];

      while (arr.length) {
        results.push(arr.splice(0, size));
      }

      return results;
    },

    removeFromArray(arr, val) {
      return arr.filter((item) => item !== val);
    },

    includesSome(arr, includes) {
      return arr.some((r) => includes.includes(r));
    },
  },
};
