export default {
  methods: {
    groupBy: (key) => (array) => array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      // eslint-disable-next-line
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {}),

    flatten(object) {
      return Object.assign({}, ...(function _flatten(o) {
        return [].concat(...Object.keys(o)
          .map((k) => (typeof o[k] === 'object'
            ? _flatten(o[k])
            : ({ [k]: o[k] }))));
      }(object)));
    },

    filterObj(obj, predicate) {
      const objArray = Object.keys(obj)
        .filter((key) => predicate(obj[key], key))
        .map((key) => ({ [key]: obj[key] }));

      return (objArray.length > 0) ? Object.assign(...objArray) : {};
    },

    resolvePath(obj, path, defaultValue) {
      return path.split('.').reduce((o, p) => o?.[p] ?? defaultValue, obj);
    },

    cloneObject(object) {
      return JSON.parse(JSON.stringify(object));
    },
  },
};
