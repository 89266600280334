export default {
  methods: {
    buildFilterQuery(args, options = {}) {
      const params = Object.keys(args);
      const result = {};

      params.forEach((param) => {
        const value = args[param];

        if (param === 'query') {
          result.query = { ...result.query, ...value };
        } else {
          const { logicalOp, compareOp } = options[param] ?? options;

          if (value.length > 0) {
            if (Array.isArray(value)) {
              result.query = result?.query ?? {};

              result.query[param] = {
                [logicalOp ?? 'or']: value.map((arg) => {
                  const argValue = (compareOp === 'like') ? `%${arg}%` : arg;
                  return { [compareOp ?? 'eq']: argValue };
                }),
              };
            } else {
              result[param] = (compareOp) ? `${compareOp}:${value}` : value;
            }
          }
        }
      });

      if (result.query) {
        result.query = JSON.stringify(result.query);
      }

      return result;
    },

    buildSortQuery(args) {
      if (!args.value) return {};
      return { sort_by: args.value.split(',').map((value) => `${value}.${args.direction}`).join(',') };
    },
  },
};
