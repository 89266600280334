<template>
  <div>
    <section class="project-header  pos-relative">
      <div class="row">
        <div class="col-24">
          <template v-if="['published', 'closed', 'staffed'].includes(project.status)">
            <StatusTags :project="project"></StatusTags>
          </template>
        </div>
        <div class="col-24">
          <h2 class="h2  mb-0  font-weight-normal">
            ID{{ project.id }}: {{ project.title }}
          </h2>
        </div>
        <div class="col-24  mt-md-xs  mt-sm-xs  mt-xs-xs" v-if="$matchMedia.md">
          <Button @click.native="$eventBus.$emit('open-modal-projectDetail')" :class="['-orange', {'-full-width  text-center': $matchMedia.xs}]">{{ $t('projectDetails.prompt.button.apply') }}</Button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getProject } from '@/api/projects.api';

import StatusTags from '@/components/modules/Project/StatusTags.vue';

export default {
  name: 'ProjectHeader',
  props: {
    project: Object,
  },
  data() {
    return {
      id: this.project.id,
    };
  },
  computed: {
    ...mapGetters([
      'loggedIn',
    ]),
  },
  watch: {
    id(newVal) {
      if (!newVal) {
        this.$set(this, 'id', {});
      } else {
        this.fetchProject();
      }
    },
  },
  methods: {
    recent(project) {
      return (new Date(project.published_at).getTime() > (new Date().getTime() - (7 * 24 * 60 * 60 * 1000)));
    },

    closed(project) {
      return (new Date(project.application_deadline).getTime() < (new Date().getTime())) || this.status === 'closed';
    },

    fetchProject() {
      getProject(parseInt(this.id, 10))
        .then((response) => response.data)
        .then(({ data }) => {
          this.$set(this, 'project', data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.replace({ name: 'NotFound', params: { lang: this.$i18n.locale } });
          }
        });
    },
  },
  components: {
    StatusTags,
    Button: () => import('@/components/atoms/Button.vue'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-header.scss"></style>
